/** @format */

@font-face {
	font-family: 'font-bold';
	src: local('font'), url(./assets/fonts/font-bold.ttf) format('opentype');
	font-display: swap;
}
@font-face {
	font-family: 'font-primary';
	src: local('font'), url(./assets/fonts/font-primary.ttf) format('opentype');
	font-display: swap;
}

:root {
	--primary-color: #EF4E37;
	--secondary-color: #000000;
	--font-primary: 'font-primary';
	--font-bold: 'font-bold';
	--rating-radius: 0%;
}
